<template>
  <div id="NewsList">
    <div class="header">
      <span>NEWS</span>
      <h2>最新消息</h2>
    </div>
    <div class="list">
      <router-link
        :to="`/news/${item.news_id}`"
        v-for="item in news_data"
        :key="item.news_id"
        class="card"
      >
        <span class="tag">{{ item.category }}</span>
        <p class="date">{{ item.create_time.slice(0, 10) }}</p>
        <h4>{{ item.title }}</h4>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsList',
  data() {
    return {
      news_data: [],
    };
  },
  methods: {
    async GetNewsoData() {
      let result = await this.SendGetData(
        process.env.VUE_APP_BASE_API + 'news/get_news_list.php'
      );
      if (result != 'error') {
        let data = JSON.parse(result.data);
        this.news_data = data;
        this.news_data.sort((a, b) => {
          return a.position - b.position;
        });
        this.$nextTick(() => {
          window.prerenderReady = true;
        });
      }
    },
  },
  created() {
    this.GetNewsoData();
  },
  metaInfo() {
    return {
      title: '最新消息｜欣富通｜SIFUTON',
      meta: [
        {
          property: 'og:description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'og:description',
        },
        {
          property: 'twitter:description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'twitter:description',
        },
        {
          property: 'description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'description',
        },
        {
          property: 'og:image',
          content: 'https://www.sifuton.com.tw/img/share/index.jpg',
          vmid: 'og:image',
        },
        {
          property: 'apple-mobile-web-app-title',
          content: '最新消息｜欣富通｜SIFUTON',
          vmid: 'apple-mobile-web-app-title',
        },
        {
          property: 'application-name',
          content: '最新消息｜欣富通｜SIFUTON',
          vmid: 'application-name',
        },
        {
          property: 'og:site_name',
          content: '最新消息｜欣富通｜SIFUTON',
          vmid: 'og:site_name',
        },
        {
          property: 'og:type',
          content: 'website',
          vmid: 'og:type',
        },
        {
          property: 'og:locale',
          content: 'zh_tw',
          vmid: 'og:locale',
        },
      ],
    };
  },
};
</script>
